import React, { FC, useState, useEffect, useRef } from 'react';
import Select from 'react-select';

import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import { TModalFullScreen, TModalSize } from '../../../type/modal-type';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import 'bootstrap/dist/css/bootstrap.min.css';
import LuckShack_img from "../../../../src/assets/img/LuckShack_img.jpeg";
interface ICommonUpcomingEventsProps {
    isFluid?: boolean;
}
const CashAppLink: FC<ICommonUpcomingEventsProps> = ({
    isFluid }) => {

    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const loaderRef = useRef<HTMLDivElement>(null);
    const [updateStatus, setUpdateStatus] = useState<any[]>([]);
    const [token, setToken] = useState('');
    const [cashappList, setCashappList] = useState<any[]>([]);

    const [cashappData, setCashappData] = useState({
        link: '',
    });
    const [linkErrors, setLinkErrors] = useState({
        link: ''
    });
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setCashappData({
            ...cashappData,
            [name]: value,
        });
    };
    const fetchAndRedirect = async () => {
        try {
            // Fetch CashApp links
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cash-app?status=Active`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    // Add authorization header if needed
                },
            });
            console.log("response of arary of cashapp link===", response);
            const links = response.data;

            if (links.length === 0) {
                console.log("No links available");
                return;
            }
            // Choose a random link
            const randomIndex = Math.floor(Math.random() * links.length);
            const randomLink = links[randomIndex].link;

            // Redirect to the selected link
            window.location.href = randomLink;
        } catch (error) {
            console.error("Error fetching CashApp links:", error);
        }
    };

    return (
        <PageWrapper>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                    marginTop: "30px",
                    backgroundColor: "#F7F7F7",
                    paddingTop: "20px",
                    width: "70%",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",

                }}
            >
                <img src={LuckShack_img} alt='' width='50%' height="60%" />
                <Button
                    onClick={fetchAndRedirect}
                    style={{
                        height: "15%",
                        width: "50%",
                        backgroundColor: "#D1A756",
                        color: "#fff",
                        fontSize: "20px",
                        borderRadius: "8px",
                        padding: "10px",
                        fontWeight: "bold",
                        marginTop: "40px",
                        transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.currentTarget as HTMLElement).style.backgroundColor = "#6C8058"} // Green color similar to the logo
                    onMouseLeave={(e) => (e.currentTarget as HTMLElement).style.backgroundColor = "#D1A756"}
                >
                    Pay Now
                </Button>
            </div>

            {/*}      <Modal
                isOpen={state}
                setIsOpen={setState}
                size='lg'>
                <ModalHeader setIsOpen={setState} className='p-4'>
                    <h3>Add Cashapp</h3>
                </ModalHeader>
                <ModalBody className='px-4'>
                    <div className='row g-4'>
                        <FormGroup id='link' label='Casapp link' className='col-md-6'>
                            <Input
                                onChange={handleChange}
                             //onChange={(e) => setCashappData({ ...cashappData, link: e.target.value })}
                                name='link'
                                value={cashappData.link} />
                        </FormGroup>
                        {linkError && (
                            <small
                                style={{ color: "red" }}
                            >{linkError}</small>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button color='info' onClick={AddCashapp}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal> */}
        </PageWrapper >
    );
};
CashAppLink.defaultProps = {
    isFluid: false,
};

export default CashAppLink;

