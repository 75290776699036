import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};
	const [signPassword, setSignPassword] = useState<boolean>(false);

	const [userData, setUserData] = useState({
		email: '',
		password: ''
	});
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: userData.email,
			loginPassword: userData.password
		},

		validate: (values) => {

			const errors: { loginUsername?: string; loginPassword?: string } = {};
			if (!values.loginUsername) {
				console.log("Error in email");

				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (usernameCheck(values.loginUsername)) {
				if (passwordCheck(values.loginUsername, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginUsername);
					}

					handleOnClick();
				} else {
					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			}
		},
	});
	const handleContinue = () => {
		setIsLoading(true);
		setEmailError('');
		try {
			setTimeout(() => {
				if (!userData.email) {
					setEmailError('Email is required.');
				} else {
					setSignPassword(true);
				}
				setIsLoading(false);
			}, 1000);
		} catch (error: any) {
			console.error('Login failed:', error.message);
			setIsLoading(false);
		}
	};
	const handleChange = (e: any) => {
		const name = e.target.name;
		const value = e.target.value;
		if (name === 'email') {
			setEmailError('');
		}
		if (name === 'password') {
			setPasswordError('');
		}
		setUserData({ ...userData, [name]: value });
	};
	const login = async (e: any, url: any) => {
		e.preventDefault();
		setPasswordError('');
		if (!userData.password) {
			setPasswordError('Password is required.');
		}
		try {
			const response = await axios.post(process.env.REACT_APP_API_URL + '/login', userData);
			const token = localStorage.setItem('Token', response.data);
			navigate("/dashboard");

		} catch (error: any) {
			console.error('Login failed:', error);
			const loginStatusCode = error.response.status;
			if (loginStatusCode === 403 && userData.password) {
				setPasswordError('Enter valid Email and Password');
			}
		}
	};
	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<LoginHeader isNewUser={singUpStatus} />


								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Your email'>
													<Input type='email' autoComplete='email' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Your name'>
													<Input autoComplete='given-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Your surname'>
													<Input autoComplete='family-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={handleOnClick}>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<div><FormGroup
													id='loginUsername'
													isFloating
													label='Your email or username'
													className={classNames({
														'd-none': signInPassword
													})}
													style={{ marginBottom: '5px' }}>
													<Input
														autoComplete='username'
														name='email'
														value={userData.email}
														//	value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={handleChange}
														//onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>

												</FormGroup>
													{emailError && <div style={{ color: 'red' }}>{emailError}</div>}

												</div>
												<div>
													<FormGroup
														id='loginPassword'
														isFloating
														label='Password'
														className={classNames({
															//'d-none': !signInPassword,
															'd-none': !signPassword,
														})}
														style={{ marginBottom: '5px' }}
													>
														<Input
															type='password'
															autoComplete='current-password'
															value={userData.password}
															name='password'
															//	value={formik.values.loginPassword}
															isTouched={formik.touched.loginPassword}
															invalidFeedback={
																formik.errors.loginPassword
															}
															validFeedback='Looks good!'
															isValid={formik.isValid}
															onChange={handleChange}
															//onChange={formik.handleChange}
															onBlur={formik.handleBlur}
														/>
													</FormGroup>
													{passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}

												</div>
											</div>
											<div className='col-12'>

												{!signPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														//isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) :
													(

														<Button
															color='warning'
															className='w-100 py-3'
															onClick={login}>
															Login
														</Button>
													)}
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
